<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex">
        <div class="w-100 d-lg-flex justify-content-center">
          <div id="login-intro" style="flex: 1 1 0%; box-sizing: border-box;">
            <div class="logo">
              <img class="ng-tns-c25-5" src="/assets/shield-white.svg">
            </div>
            <div class="title ng-trigger ng-trigger-animate">
              You've registered for <span class="ng-tns-c25-5" style="font-family: boxen-bold;">Secris</span>
            </div>
            <div class="description ng-trigger ng-trigger-animate">
              Check your inbox for your verification email.
            </div>
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            REGISTERED
          </b-card-title>
          <b-card-text class="mb-2">
            Thank you for registering. Please check your inbox for the verification email.
          </b-card-text>
          <b-card-text class="text-center mt-2">
            <span>Already have an account?  <b-link :to="{name:'auth-login'}">
              <small>Login</small>
            </b-link></span>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BCardText, BCardTitle,
} from 'bootstrap-vue'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sideImg: require('@/assets/images/pages/login-v2.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
  },
}
</script>
